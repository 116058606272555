import { gql } from "@apollo/client";

export const ADD_TABLE = gql`
  mutation addTable($number: Int!, $qr_code: String!, $fk_restaurant: Int!) {
    addTable(Number: $number, QR_Code: $qr_code, Fk_Restaurant: $fk_restaurant)
  }
`;

export const GET_TABLE_BY_ID = gql`
  query getTableById($id: Int!) {
    getTableById(id: $id) {
      id
      Number
      QR_Code
      Fk_Restaurant
    }
  }
`;

export const GET_TABLE = gql`
  query getTable {
    getTable {
      id
      Number
      QR_Code
      Fk_Restaurant
    }
  }
`;

export const DELETE_TABLE = gql`
  mutation deleteTable($id: Int!) {
    deleteTable(id: $id)
  }
`;

export const UPDATE_TABLE = gql`
  mutation updateTable(
    $id: Int!
    $number: Int
    $qr_code: String
    $fk_restaurant: Int!
  ) {
    updateTable(
      id: $id
      Number: $number
      QR_Code: $qr_code
      Fk_Restaurant: $fk_restaurant
    )
  }
`;
