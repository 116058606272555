import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Paper, Typography, Grid, IconButton } from "@material-ui/core";
import { Button, AppBar, Tabs } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MenuItemTable from "../MenuItemTable";
import AddMenuItemDialog from "../AddMenuItemDialog";
import EditMenuItemDialog from "../EditMenuItemDialog";
import DeleteMenuItemDialog from "../DeleteMenuItemDialog";
import PropTypes from "prop-types";

import {
  ADD_ITEM,
  DELETE_ITEM,
  GET_ITEM,
  UPDATE_ITEM,
} from "../../pages/Item/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Axios from "axios";
import Tab from "@material-ui/core/Tab";
import { PhonelinkLockOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditMenuDialog from "../EditMenuDialog";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 320,
    display: "flex",
    margin: theme.spacing(1),
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  BoxContainer: {
    background: "white",
    padding: theme.spacing(3),
  },
  BoxItemONe: {
    marginBottom: "10px",
  },
  BoxItemTwo: {},
  Button: {
    color: "white",
  },
  BoxChild: {
    margin: "1rem 0px 1rem 0px",
  },
  button: {
    margin: theme.spacing(1),
  },
  TabStyle: {
    display: "inline-table ",

    backgroundColor: "#37A9EC",
    color: "white",
    borderRadius: "5px",
    minHeight: "49px",
  },
  menuStyle: {
    color: "#37A9EC",
  },
  iconLabelWrapper: {
    display: "inline-table !important",
    maxWidth: "100%",
  },
  labelContainer: {
    border: "1px solid red",
    height: "100%  !important ",
  },
}));

function TabPanel(props) {
  const { children, value, index, data, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && data && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
};
function MenuItem({ data, handleOpenAddMenuDialog, editMenu }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const { id } = useParams();

  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [Image, setImage] = useState("");
  const [Price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [Fk_TaxCategory, setFk_TaxCategory] = useState("");
  const [Fk_Currency, setFk_Currency] = useState("");

  const [menuItemImageSelected, setMenuItemImageSelected] = useState("");
  const [editMenuItemImageSelected, setEditMenuItemImageSelected] = useState(
    ""
  );

  const [menuITemImageLoading, setMenuItemImageLoading] = useState(false);
  const [menuItemImageUrl, setMenuItemImageUrl] = useState("");

  const [open, setOpen] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeletetModal, setOpenDeleteModal] = React.useState(false);

  const [menuImage, setMenuImage] = useState("");
  const [editMenuITemImageLoading, setEditMenuItemImageLoading] = useState(
    false
  );
  const [editMenuItemImageUrl, setEditMenuItemImageUrl] = useState("");

  const [currentMenuItem, setCurrentMenuItem] = React.useState({});
  const [deleteMenuItemId, setDeleteMenuItemId] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState();
  const [DiscountedPrice, setDiscountedPrice] = React.useState("");

  const [openEditMenuModel, setOpenEditMenuModel] = React.useState(false);
  const [getItem, { loading, error, data: dataItem }] = useLazyQuery(GET_ITEM, {
    variables: {
      id: +id,
    },
    fetchPolicy: "network-only",
  });
  const [
    addItem,
    { loading: resData, error: graphQlError, data: dataRes },
  ] = useMutation(ADD_ITEM);

  const [
    updateItem,
    { loading: editData, error: editGraphQlError, data: editDataRes },
  ] = useMutation(UPDATE_ITEM);
  const [
    deleteItem,
    { loading: deleteLoading, err: deleteErr, data: deleteDataRes },
  ] = useMutation(DELETE_ITEM);

  useEffect(() => {
    getItem();
  }, []);
  const [currentEditMenu, setCurrentEditMenu] = useState({});
  const handleOpenEditMenuDialog = ({ id, name }) => {
    console.log("cliked");
    setOpenEditMenuModel(true);
    setCurrentEditMenu({ id, name });
  };
  const handleEditMenuDialogClose = () => {
    setOpenEditMenuModel(false);
  };

  const handleOpenAddMenuItemDialog = () => {
    setOpen(true);
  };
  const handleAddMenuItemDialogClose = () => {
    setOpen(false);
  };

  const handleOpenEditMenuItemDialog = (Item) => {
    setOpenEditModal(true);
    setCurrentMenuItem(Item);
  };
  const handleEditMenuItemDialogClose = () => {
    setOpenEditModal(false);
  };

  const handleOpenDeleteMenuItemDialog = (id) => {
    setDeleteMenuItemId(id);
    setOpenDeleteModal(true);
  };
  const handleDeleteMenuItemDialogClose = () => {
    setOpenDeleteModal(false);
  };

  const isFormValid = () => {
    if (Name !== "") {
      return true;
    } else {
      return false;
    }
  };
  const createItem = async (ingredientsState) => {
    try {
      if (isFormValid()) {
        setMenuItemImageLoading(true);
        const data = new FormData();
        data.append("file", menuItemImageSelected);
        data.append("upload_preset", "ml_default");

        await Axios.post(
          "https://api.cloudinary.com/v1_1/myorder/image/upload",
          data
        ).then((res) => {
          setMenuItemImageLoading(false);
          console.log("newwimg", menuItemImageUrl);

          setMenuItemImageUrl();
          addItem({
            variables: {
              name: Name,
              description: Description,
              ingredients: ingredientsState,
              image: res.data.url,
              price: +Price,
              discountedPrice: +DiscountedPrice,
              category: "some category",
              fk_restaurant: +id,
              fk_menu: value,
            },
          }).then((res) => {
            setName("");
            setDescription("");
            setImage("");
            setPrice("");
            setCategory("");
            setDiscountedPrice("");
            setMenuItemImageUrl("");
            setMenuItemImageSelected("");
            getItem();
            setOpen(false);
          });
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const editItem = async (ingredientsState) => {
    let newIngredientsArr = ingredientsState.map((ing) => {
      return {
        Name: ing.Name,
        Amount: ing.Amount,
        Origin: ing.Origin,
        Unit: ing.Unit,
      };
    });
    try {
      setEditMenuItemImageLoading(true);
      const data = new FormData();
      data.append("file", editMenuItemImageSelected);
      data.append("upload_preset", "ml_default");

      await Axios.post(
        "https://api.cloudinary.com/v1_1/myorder/image/upload",
        data
      ).then((res) => {
        console.log(res, "cloudndn");
        setEditMenuItemImageLoading(false);
        updateItem({
          variables: {
            id: +currentMenuItem.id,
            name: currentMenuItem.Name,
            category: currentMenuItem.Category,
            description: currentMenuItem.Description,
            price: +currentMenuItem.Price,
            discountedPrice: +currentMenuItem.DiscountedPrice,
            ingredients: newIngredientsArr,
            fk_restaurant: +id,
            fk_menu: value,

            image: res.data.url !== "" ? res.data.url : currentMenuItem.Image,
          },
        }).then((res) => {
          getItem();
          setEditMenuItemImageUrl("");
          setOpenEditModal(false);
        });
      });
    } catch (e) {
      console.log("res");

      console.error(e);
    }
  };

  const handleEditFormChange = (e) => {
    setCurrentMenuItem({
      ...currentMenuItem,
      [e.target.name]: e.target.value,
    });
  };

  const deleteMenuItem = async (itemId) => {
    try {
      await deleteItem({
        variables: {
          id: itemId,
        },
      }).then((res) => {
        getItem();
        setOpenDeleteModal(false);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteMenuId = (id) => {
    setDeleteMenuItemId(id);
  };
  const handleEditMenu = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      throw err;
    }
  };

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let filteredArr;
  if (dataItem && dataItem.getItem.length) {
    filteredArr = dataItem.getItem.filter((m) => m.Fk_Menu == value);
  }
  let idExists = false;
  let n =
    data &&
    data.getRestaurantById &&
    data.getRestaurantById.Menu.forEach((menu) => {
      if (menu.id == value) {
        idExists = true;
      }
      return null;
    });
  return (
    <>
      <Box
        className={classes.BoxContainer}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="h4" color="initial">
          {data && data.getRestaurantById && data.getRestaurantById.Name}
        </Typography>

        <Box className={classes.BoxChild}>
          <Typography variant="h6" color="initial">
            Menu
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Tabs
              value={value}
              indicatorColor="primary"
              onChange={handleChange}
              aria-label="simple tabs example"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              {data &&
                data.getRestaurantById &&
                data.getRestaurantById.Menu.map((menu) => {
                  const matchId = menu.id;
                  console.log("ma", matchId);
                  return (
                    <Tab
                      classes={{
                        wrapper: classes.iconLabelWrapper,
                        // labelContainer: classes.labelContainer,
                      }}
                      disableRipple
                      className={
                        value === menu.id ? classes.TabStyle : classes.menuStyle
                      }
                      label={menu.Name}
                      id={`simple-tab-${menu.id}`}
                      ariaControls={`simple-tabpanel-${menu.id}`}
                      value={menu.id}
                      indicatorColor={false}
                      icon={
                        value === menu.id && (
                          <EditIcon
                            onClick={() =>
                              handleOpenEditMenuDialog({
                                id: menu.id,
                                name: menu.Name,
                              })
                            }
                            fontSize="inherit"
                            style={{
                              float: "right ",
                            }}
                          />
                        )
                      }
                    />
                  );
                })}
            </Tabs>

            <IconButton>
              <AddIcon
                onClick={handleOpenAddMenuDialog}
                style={{ fontWeight: "bolder", color: "#37A9EC" }}
                fontSize="large"
              />
            </IconButton>
          </Box>
          <Box my={1}>
            <TabPanel
              value={value}
              index={value}
              data={filteredArr !== undefined ? filteredArr : []}
            >
              {data &&
                data.getRestaurantById &&
                data.getRestaurantById.Menu.length > 0 &&
                idExists && (
                  <MenuItemTable
                    handleOpenAddMenuItemDialog={handleOpenAddMenuItemDialog}
                    data={filteredArr}
                    handleOpenDeleteMenuItemDialog={
                      handleOpenDeleteMenuItemDialog
                    }
                    handleOpenEditMenuItemDialog={handleOpenEditMenuItemDialog}
                  />
                )}
            </TabPanel>
          </Box>
        </Box>
      </Box>

      <Box>
        <AddMenuItemDialog
          open={open}
          onClose={handleAddMenuItemDialogClose}
          createItem={createItem}
          Name={Name}
          setName={setName}
          Description={Description}
          setDescription={setDescription}
          Image={Image}
          setImage={setImage}
          Price={Price}
          setPrice={setPrice}
          Fk_TaxCategory={Fk_TaxCategory}
          setFk_TaxCategory={setFk_TaxCategory}
          Fk_Currency={Fk_Currency}
          setFk_Currency={setFk_Currency}
          setMenuItemImageSelected={setMenuItemImageSelected}
          menuITemImageLoading={menuITemImageLoading}
          setMenuItemImageLoading={setMenuItemImageLoading}
          menuItemImageUrl={menuItemImageUrl}
          menuItemImageSelected={menuItemImageSelected}
          setDiscountedPrice={setDiscountedPrice}
          DiscountedPrice={DiscountedPrice}
        />
      </Box>
      <Box>
        <EditMenuItemDialog
          currentMenuItem={currentMenuItem}
          open={openEditModal}
          onClose={handleEditMenuItemDialogClose}
          editItem={editItem}
          handleEditFormChange={handleEditFormChange}
          editMenuITemImageLoading={editMenuITemImageLoading}
          setEditMenuItemImageLoading={setEditMenuItemImageLoading}
          setEditMenuItemImageUrl={setEditMenuItemImageUrl}
          editMenuItemImageUrl={editMenuItemImageUrl}
          setEditMenuItemImageSelected={setEditMenuItemImageSelected}
          editMenuItemImageSelected={editMenuItemImageSelected}
          handleEditMenuItemDialogClose={handleEditMenuItemDialogClose}
          menuImage={menuImage}
          setMenuImage={setMenuImage}
          // test={test}
        />
      </Box>
      <Box>
        <DeleteMenuItemDialog
          open={openDeletetModal}
          onClose={handleDeleteMenuItemDialogClose}
          deleteItem={deleteMenuItem}
          deleteMenuItemId={deleteMenuItemId}
          selectedValue={selectedValue}
        />
      </Box>
      <Box>
        <EditMenuDialog
          setCurrentEditMenu={setCurrentEditMenu}
          setName={setName}
          currentEditMenu={currentEditMenu}
          editMenu={editMenu}
          openEditMenuModel={openEditMenuModel}
          onClose={handleEditMenuDialogClose}
        />
      </Box>
    </>
  );
}

export default MenuItem;
