import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Image } from "cloudinary-react";
import { blue } from "@material-ui/core/colors";
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
} from "@material-ui/core";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  text: {
    color: "white",
  },
  textfield: {
    textAlign: "center",
  },
  imageUpload: {
    textAlign: "center",
  },
});

function EditInfoDialog(props) {
  const classes = useStyles();
  const {
    currentInfo,
    handleOpenEditRestaurantInfoDialogClose,
    onClose,
    selectedValue,
    open,
    handleEditInfoForm,
    updateInfo,
    setCurrentInfo,
    uploadRestaurantImage,
    restaurantImageUploading,
    setRestaurantImageSelected,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Edit Restaurant Info</DialogTitle>
      <Box mx={1} p={1}>
        <form
          onSubmit={(e) => updateInfo(e)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="Name"
                value={currentInfo.Name}
                onChange={(e) => handleEditInfoForm(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textfield}
                margin="dense"
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                name="Phone"
                value={currentInfo.Phone}
                onChange={(e) => handleEditInfoForm(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textfield}
                margin="dense"
                id="outlined-basic"
                label="City"
                variant="outlined"
                name="City"
                value={currentInfo.City}
                onChange={(e) => handleEditInfoForm(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textfield}
                margin="dense"
                id="outlined-basic"
                label="Mail"
                variant="outlined"
                name="Mail"
                value={currentInfo.Mail}
                onChange={(e) => handleEditInfoForm(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textfield}
                margin="dense"
                id="outlined-basic"
                label="PLZ"
                variant="outlined"
                name="PLZ"
                value={currentInfo.PLZ}
                onChange={(e) => handleEditInfoForm(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textfield}
                margin="dense"
                id="outlined-basic"
                label="Category"
                variant="outlined"
                name="Category"
                value={currentInfo.Category}
                onChange={(e) => handleEditInfoForm(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textfield}
                margin="dense"
                id="outlined-basic"
                label="Phone for orders (SMS)"
                variant="outlined"
                name="Phone_for_Orders"
                value={currentInfo.Phone_for_Orders}
                onChange={(e) => handleEditInfoForm(e)}
              />
            </Grid>
          </Grid>

          <Box margin={2} minWidth="xs" display="flex" justifyContent="center">
            <Box>
              <Button
                onClick={handleOpenEditRestaurantInfoDialogClose}
                variant="text"
                disableRipple
                style={{ padding: "4.5px 3.5rem", marginRight: "4px" }}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{
                  padding: "5px 4rem",
                  color: "white",
                  marginRight: "4px",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

EditInfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default EditInfoDialog;
