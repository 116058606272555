import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Image } from "cloudinary-react";
import { CircularProgress, IconButton } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import IngredientsTable from "../IngredientsTable";
import IngredientsDialog from "../IngredientsDialog";
const useStyles = makeStyles({
  contaier: {
    display: "flex",
    flexDirection: "column",
  },

  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  controlButtonStyle: {
    width: "5rem",
  },
  imageUpload: {
    textAlign: "center",
  },
});

export default function EditItemDialog(props) {
  const [showIngredientsDialog, setShowIngredientsDialog] = useState(false);
  const [ingredientsState, setIngredientsState] = useState(
    props.currentMenuItem.Ingredients || []
  );
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    editItem,
    handleEditFormChange,
    currentMenuItem,
    editMenuItemImageUrl,
    handleEditMenuItemDialogClose,
    setMenuItemImageSelected,
    setEditMenuItemImageSelected,
    editMenuITemImageLoading,
    setEditMenuItemImageLoading,
  } = props;
  const handleClose = (e) => {
    e.preventDefault(e);

    onClose(selectedValue);
  };

  const handleShowIngredientsDialog = (value) => {
    setShowIngredientsDialog(value);
  };
  const handleEditMenuItem = (e) => {
    e.preventDefault(e);
    setEditMenuItemImageLoading(true);
    editItem(ingredientsState);
  };

  const handleIngredientsSubmitEdit = (ingredientData) => {
    debugger;
    let checkd = [...ingredientsState, ingredientData];
    setIngredientsState([...ingredientsState, ingredientData]);

    setShowIngredientsDialog(false);
  };

  const handleDeleteIngredient = (ingredientName) => {
    let ingData = ingredientsState.filter((ing) => ing.Name !== ingredientName);
    setIngredientsState(ingData);
  };

  useEffect(() => {
    setIngredientsState(props.currentMenuItem.Ingredients);
  }, [props.currentMenuItem.Ingredients]);
  return (
    <Dialog maxWidth="md" aria-labelledby="simple-dialog-title" open={open}>
      <Box mx={1} p={1}>
        <form
          onSubmit={(e) => handleEditMenuItem(e)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Box
                display="flex"
                justifyContent="space-around"
                justifyItems="center"
                m={2}
                className={classes.imageUpload1}
              >
                <Box>
                  <Typography variant="h4" color="initial" align="left">
                    {" "}
                    Edit {props.currentMenuItem.Name}
                  </Typography>
                </Box>

                <Image
                  style={{
                    maxWidth: "150px",
                    height: "150px",

                    borderRadius: "50%",
                  }}
                  cloudName="myorder"
                  publicId={
                    editMenuItemImageUrl != ""
                      ? editMenuItemImageUrl
                      : currentMenuItem.Image
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Name"
                name="Name"
                size="small"
                fullWidth
                value={currentMenuItem.Name}
                onChange={(e) => handleEditFormChange(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                label="Price"
                name="Price"
                InputLabelProps={{ shrink: true }}
                value={currentMenuItem.Price}
                fullWidth
                onChange={(e) => handleEditFormChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                // size="small"
                label="Description"
                name="Description"
                type="text"
                value={currentMenuItem.Description}
                multiline
                rows={3}
                fullWidth
                onChange={(e) => handleEditFormChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Discounted Price"
                name="DiscountedPrice"
                variant="outlined"
                value={currentMenuItem.DiscountedPrice}
                fullWidth
                onChange={(e) => handleEditFormChange(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="flex-start">
                <TextField
                  type="file"
                  size="small"
                  className={classes.textfield}
                  margin="dense"
                  style={{ minWidth: "200px", maxWidth: "236px" }}
                  id="outlined-basic"
                  variant="outlined"
                  label="select image"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setEditMenuItemImageSelected(e.target.files[0])
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography>Ingredients</Typography>
                <IngredientsTable
                  handleShowIngredientsDialog={handleShowIngredientsDialog}
                  ingredients={ingredientsState}
                  handleDeleteIngredient={handleDeleteIngredient}
                />
              </Box>
            </Grid>
          </Grid>
          <Box minWidth="xs" display="flex" justifyContent="center">
            <Box p={4}>
              <Button
                onClick={handleEditMenuItemDialogClose}
                variant="text"
                color="primary"
                disableRipple
                style={{ padding: "5px 4rem", color: "grey" }}
              >
                Cancel
              </Button>
            </Box>

            <Box p={4}>
              {editMenuITemImageLoading === true ? (
                <CircularProgress size="3rem" />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ padding: "5px 4rem", color: "white" }}
                >
                  Save
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Box>
      <IngredientsDialog
        showIngredientsDialog={showIngredientsDialog}
        // setShowIngredientsDialog={setShowIngredientsDialog}
        handleShowIngredientsDialog={handleShowIngredientsDialog}
        setIngredientsState={setIngredientsState}
        ingredientsState={ingredientsState}
        handleIngredientsSubmit={handleIngredientsSubmitEdit}
      />
    </Dialog>
  );
}

EditItemDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
