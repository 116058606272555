import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Fade, Typography } from "@material-ui/core";
import Details from "../Details";
import {
  GET_RESTAURANT_BY_ID,
  UPDATE_RESTAURANT,
  UPDATE_RESTAURANT_STATUS,
} from "../../pages/Restaurant/graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import TableDialog from "../AddTableDailog";
import DeleteTableDialog from "../DeleteTableDialog";
import EditTableDialog from "../EditTableDialog";
import EditRestaurantInfoDialog from "../EditRestaurantInfoDialog";
import Axios from "axios";

import {
  ADD_TABLE,
  GET_TABLE,
  DELETE_TABLE,
  UPDATE_TABLE,
} from "../../pages/Details/graphql";
import { useMutation } from "@apollo/client";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    padding: theme.spacing(3),
  },
}));

function RestaurantDetails(props) {
  const { window } = props;
  const classes = useStyles();
  const { id } = useParams();
  const [qrData, setQrData] = useState("");

  const [open, setOpen] = useState(false);
  const [openAddQrCode, setOpenAddQrCode] = useState(false);

  const [qr_code, setQr_code] = React.useState("");
  const [number, setNumber] = React.useState("");

  const [editQr_code, setEditQr_code] = React.useState();
  const [openQrCode, setOpenQrCode] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState();

  const [openDelDialog, setOpenDelDialog] = React.useState(false);
  const [deleteTableId, setDeleteTableId] = React.useState(null);
  const [selectedValueDel, setSelectedValueDel] = React.useState();

  const [openEditDialog, setOpenEditDialog] = React.useState();
  const [selectedValueEdit, setSelectedValueEdit] = React.useState();
  const [currentTable, setCurrentTable] = React.useState({});
  const [openEditInfoDialog, setOpenEditInfoDialog] = React.useState(false);
  const [selectedValueInfo, setSelectedValueInfo] = React.useState();
  const [currentInfo, setCurrentInfo] = React.useState({});

  const [
    updateRestaurantOpening,
    { loading: resLoading, error: resErr, data: resDataStatus },
  ] = useMutation(UPDATE_RESTAURANT_STATUS);

  const [state, setState] = React.useState({
    isRestaurantOpen: null,
  });

  const [getRestaurantById, { loading, error, data }] = useLazyQuery(
    GET_RESTAURANT_BY_ID,
    {
      variables: {
        id: +id,
      },
      fetchPolicy: "network-only",
    }
  );
  const [
    getTable,
    { loading: tableLoad, error: tableErr, data: tableData },
  ] = useLazyQuery(GET_TABLE, {
    variables: {
      id: +id,
      fk_restaurant: +id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getRestaurantById();
  }, []);

  useEffect(() => {
    if (data) {
      setState({
        isRestaurantOpen: data.getRestaurantById.Is_Open,
      });
    }
  }, [data]);

  const [
    addTable,
    { loading: resData, error: graphQlError, data: dataRes },
  ] = useMutation(ADD_TABLE);

  const [
    deleteTable,
    { loading: deleteLoading, err: deleteErr, data: deleteDataRes },
  ] = useMutation(DELETE_TABLE);

  useEffect(() => {
    getTable();
  }, []);

  const [
    updateTable,
    { loading: editLoading, err: editErr, data: editDataRes },
  ] = useMutation(UPDATE_TABLE);

  const [
    updateRestaurant,
    { loading: updateLoading, err: updateErr, data: updateDataRes },
  ] = useMutation(UPDATE_RESTAURANT);

  const handleAddQrCodeScan = (data) => {
    console.log("inside handleAddQrCodeScan ", data);
    if (data != null) {
      setQr_code(data.text);
    }
  };

  const handleOpenTableDialog = () => {
    setOpen(true);
    setOpenAddQrCode(false);
  };

  const handleTableDialogClose = () => {
    setOpen(false);
  };
  const createTable = async () => {
    try {
      await addTable({
        variables: {
          number: +number,
          qr_code: qr_code,
          fk_restaurant: +id,
        },
      }).then((res) => {
        getTable();
        setNumber("");
        setQr_code("");
        setOpen(false);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteDialogOpen = (id) => {
    setOpenDelDialog(true);
    setDeleteTableId(id);
  };
  const handleOpenDelDialogClose = () => {
    setOpenDelDialog(false);
  };

  const deleteTAble = async (id) => {
    try {
      await deleteTable({
        variables: {
          id: +id,
        },
      }).then((res) => {
        getTable();
        setOpenDelDialog(false);
      });
    } catch (e) {
      console.error(e);
    }
  };
  const handleEditQrCodeScan = (data) => {
    console.log("edited", data);
    if (data != null) {
      setCurrentTable({
        ...currentTable,
        QR_Code: data.text,
      });
    }
  };

  const handleEditDialogOpen = (table) => {
    setOpenEditDialog(true);
    setCurrentTable(table);
    setOpenQrCode(false);
  };
  const handleOpenEditDialogClose = () => {
    setOpenEditDialog(false);
  };

  const updateTAble = async (e) => {
    e.preventDefault();
    try {
      await updateTable({
        variables: {
          name: currentTable.Number,
          qr_code: currentTable.QR_Code,
          id: +currentTable.id,
          fk_restaurant: +id,
        },
      }).then((res) => {
        getTable();
        setOpenEditDialog(false);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleEditTableForm = (e) => {
    e.preventDefault(e);
    setCurrentTable({
      ...currentTable,
      [e.target.name]: e.target.value,
    });
  };

  const handleOpenEditRestaurantInfoDialog = (resName) => {
    setOpenEditInfoDialog(true);
    setCurrentInfo(resName.getRestaurantById);
  };

  const handleOpenEditRestaurantInfoDialogClose = () => {
    setOpenEditInfoDialog(false);
  };

  const updateInfo = async (e) => {
    e.preventDefault();

    try {
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      console.log("curnt", currentInfo);
      await updateRestaurant({
        variables: {
          id: +currentInfo.id,
          name: currentInfo.Name,
          phone: currentInfo.Phone,
          mail: currentInfo.Mail,
          city: currentInfo.City,

          plz: currentInfo.PLZ,
          category: currentInfo.Category,
          phone_for_orders: currentInfo.Phone_for_Orders,

          fk_user: currentUser.id,
        },
      }).then((res) => {
        getRestaurantById();

        setOpenEditInfoDialog(false);
      });
    } catch (e) {
      console.error(e);
    }
  };
  const handleEditInfoForm = (e) => {
    e.preventDefault(e);
    setCurrentInfo({
      ...currentInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = async (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.checked });

    try {
      await updateRestaurantOpening({
        variables: {
          id: +id,
          is_open: e.target.checked,
        },
      }).then((res) => {
        console.log("switch", res);
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box className={classes.container}>
      <Details
        handleOpenTableDialog={handleOpenTableDialog}
        qrData={qrData}
        handleOpenEditRestaurantInfoDialog={handleOpenEditRestaurantInfoDialog}
        handleOpenEditDialogClose={handleOpenEditDialogClose}
        handleEditDialogOpen={handleEditDialogOpen}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        openDelDialog={openDelDialog}
        deleteTAble={deleteTAble}
        resName={data}
        setOpen={setOpen}
        state={state}
        handleChange={handleChange}
      />
      <TableDialog
        setOpenAddQrCode={setOpenAddQrCode}
        openAddQrCode={openAddQrCode}
        handleAddScanQrCode={handleAddQrCodeScan}
        selectedValue={selectedValue}
        open={open}
        onClose={handleTableDialogClose}
        qr_code={qr_code}
        setQr_code={setQr_code}
        number={number}
        setNumber={setNumber}
        createTable={createTable}
      />
      <DeleteTableDialog
        deleteTAble={deleteTAble}
        setOpenDelDialog={setOpenDelDialog}
        open={openDelDialog}
        onClose={handleOpenDelDialogClose}
        selectedValueDel={selectedValueDel}
        deleteTableId={deleteTableId}
      />
      <EditTableDialog
        handleEditQrCodeScan={handleEditQrCodeScan}
        handleOpenEditDialogClose={handleOpenEditDialogClose}
        currentTable={currentTable}
        setCurrentTable={setCurrentTable}
        updateTAble={updateTAble}
        open={openEditDialog}
        onClose={setOpenEditDialog}
        selectedValueEdit={selectedValueEdit}
        handleEditTableForm={handleEditTableForm}
        openQrCode={openQrCode}
        setOpenQrCode={setOpenQrCode}
      />
      <EditRestaurantInfoDialog
        handleOpenEditRestaurantInfoDialogClose={
          handleOpenEditRestaurantInfoDialogClose
        }
        open={openEditInfoDialog}
        selectedValue={selectedValueInfo}
        onClose={handleOpenEditRestaurantInfoDialogClose}
        handleEditInfoForm={handleEditInfoForm}
        updateInfo={updateInfo}
        currentInfo={currentInfo}
        setCurrentInfo={setCurrentInfo}
      />
    </Box>
  );
}

export default RestaurantDetails;
