import { gql } from "@apollo/client";

export const GET_RESTAURANT_BY_ID = gql`
  query getRestaurantById($id: Int!) {
    getRestaurantById(id: $id) {
      id
      Name
      Menu {
        id
        Name
      }
    }
  }
`;

export const ADD_MENU = gql`
  mutation addMenu($name: String!, $fk_restaurant: Int!) {
    addMenu(Name: $name, Fk_Restaurant: $fk_restaurant)
  }
`;

export const EDIT_MENU = gql`
  mutation addMenu($id: Int!, $name: String!, $fk_restaurant: Int!) {
    updateMenu(id: $id, Name: $name, Fk_Restaurant: $fk_restaurant)
  }
`;
