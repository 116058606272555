import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_RESTAURANTS_BY_USER } from "../../pages/Restaurant/graphql";
import RestaurantItem from "../RestaurantItem";
import AddIcon from "@material-ui/icons/Add";
import { Box, Paper, Button } from "@material-ui/core";
import AddRestaurantDialog from "../AddRestaurantDialog";
import { ADD_RESTAURANT } from "../../pages/Restaurant/graphql";
import Axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    display: "flex",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 29,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  Card: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    background: " white",
    padding: "1rem",
    margin: "0.5rem",
  },

  Box: {
    display: "flex",
    flexDirection: "row",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.9), 0 0 40px rgba(0, 0, 0, 0.1) ",
    padding: "5px",
    borderRadius: "5%",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    background: "white",
    height: "70px",
    width: "70px",
    // padding: "10px",
  },
}));

function Restaurant(props) {
  const { window } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const [getRestaurants, { loading, error, data }] = useLazyQuery(
    GET_RESTAURANTS_BY_USER,
    {
      fetchPolicy: "network-only",
    }
  );
  const [
    addRestaurant,
    { loading: resData, error: graphQlError, data: dataRes },
  ] = useMutation(ADD_RESTAURANT, {
    refetchQueries: [
      { query: GET_RESTAURANTS_BY_USER },
    ],
  });

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");

  const [mail, setMail] = useState("");
  const [plz, setPlz] = useState("");
  const [category, setCategory] = useState("");
  const [phone_for_orders, setPhone_for_orders] = useState("");
  const [dataloading, setDataloading] = useState(false);
  const [is_open, setIs_Open] = useState(true);

  useEffect(() => {
    getRestaurants();
  }, []);
  const handleRestaurantDialog = () => {
    setOpen(true);
    setName("");
    setPhone("");
    setCity("");

    setMail("");
    setPlz("");
    setCategory("");

    setPhone_for_orders("");
  };
  const handleRestaurantClose = () => {
    setOpen(false);
  };
  const createRestraunt = async (e) => {
    e.preventDefault();
    try {
      await addRestaurant({
        variables: {
          name: name,
          phone: phone,
          city: city,
          mail: mail,
          plz: plz,
          category: category,
          phone_for_orders: phone_for_orders,
          is_open: is_open,
          // fk_user: currentUser.id,
        },
      }).then((res) => {
        setDataloading(false);

        getRestaurants();
        setOpen(false);
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid alignItems="center" container spacing={3}>
      {data &&
        data.getRestaurantsByUser.map((res) => {
          return (
            <RestaurantItem
              restrauntId={res.id}
              resPLZ={res.PLZ}
              restrauntName={res.Name}
              restrauntImage={res.Image}
            />
          );
        })}
      <Grid item>
        <Box>
          <Paper className={classes.paper} elevation={3}>
            <IconButton
              onClick={handleRestaurantDialog}
              color="primary"
              aria-label="add to shopping cart"
            >
              <AddIcon style={{ fontWeight: "bolder" }} fontSize="large" />
            </IconButton>
          </Paper>
        </Box>
      </Grid>
      <AddRestaurantDialog
        name={name}
        setName={setName}
        phone={phone}
        setPhone={setPhone}
        city={city}
        setCity={setCity}
        mail={mail}
        setMail={setMail}
        plz={plz}
        setPlz={setPlz}
        phone_for_orders={phone_for_orders}
        setPhone_for_orders={setPhone_for_orders}
        category={category}
        setCategory={setCategory}
        open={open}
        onClose={handleRestaurantClose}
        createRestraunt={createRestraunt}
        dataloading={dataloading}
      />
    </Grid>
  );
}

export default Restaurant;
