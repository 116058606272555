import React, { useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, Link } from "react-router-dom";
import { LOGIN } from "../../pages/Login/graphql";
import { useMutation } from "@apollo/client";
import jwt from "jsonwebtoken";
import logo from "../../assets/images/logoBig.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "2rem",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url(${logo})`,
  },
  form: {
    textAlign: "center",
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const classes = useStyles();
  const history = useHistory();

  const [login, { loading, error: graphQlError, data }] = useMutation(LOGIN);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("State", state);
    try {
      await login({
        variables: {
          email: state.email,
          password: state.password,
        },
      })
        .then((res) => {
          if (res.data.login) {
            let token = res.data.login.token;
            localStorage.setItem("authToken", token);
            history.push("/app/dashboard");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box component="span">
          <img alt="logo" src={logo} height="60px" width="auto" />
        </Box>

        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="E-Mail"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
          />

          <Box mt={2} mb={2}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              disableElevation
              color="primary"
              fullWidth
              style={{
                textTransform: "capitalize",
                color: "white",
                padding: "10px",
              }}
            >
              {loading ? "Singing In" : "Log In"}
            </Button>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Button
                style={{ textTransform: "capitalize" }}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/auth/register");
                }}
              >
                {"Register"}
              </Button>
            </Grid>
          </Grid>
          <Grid>
            {graphQlError && (
              <Alert severity="error">
                {graphQlError.graphQLErrors.map(({ message }, i) => (
                  <span key={i}>{message}</span>
                ))}
              </Alert>
            )}
          </Grid>
        </form>
      </div>
    </Container>
  );
}
