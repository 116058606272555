import React, { useState } from "react";
import PasswordChecklist from "react-password-checklist";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, Link } from "react-router-dom";
import { LOGIN } from "../../pages/Login/graphql";
import { ADD_CUSTOMER } from "../../pages/Register/graphql";
import { useMutation } from "@apollo/client";
import jwt from "jsonwebtoken";
import logo from "../../assets/images/logoBig.png";
import { Snackbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "2rem",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundImage: `url(${logo})`,
  },
  form: {
    textAlign: "center",
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RegisterForm() {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [open, setOpen] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState("");

  const classes = useStyles();
  const history = useHistory();
  const [addCustomer, { loading, error: graphQlError, data }] = useMutation(
    ADD_CUSTOMER
  );

  // Regex Checks the follwoing
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const analyze = (password) => {
    if (strongRegex.test(password)) {
      console.log("pass");
      return true;
    } else {
      console.log("failed");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (analyze(state.password)) {
        await addCustomer({
          variables: {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            password: state.password,
          },
        })
          .then((res) => {
            if (res.data.addCustomer) {
              setOpen(true);
              setTimeout(() => {
                history.push("/login");
              }, 3000);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        setPasswordError("Please enter valid data");
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const handlePassword = (e) => {
  //   const password = e.target.value;
  //   const reg = /^[A-Z]*$/;
  //   const test = reg.test(password);
  //   if (test) {
  //     //   alert("pass");
  //     setState((prevState) => ({
  //       ...prevState,
  //       password: e.target.value,
  //     }));
  //   } else {
  //     console.log("chngd");
  //   }
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box component="span">
          <img alt="logo" src={logo} height="60px" width="auto" />
        </Box>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                variant="outlined"
                margin="normal"
                fullWidth
                id="firstName"
                label="Firstname"
                name="firstName"
                type="text"
                autoComplete="firstName"
                autoFocus
                value={state.firstName}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                variant="outlined"
                margin="normal"
                fullWidth
                id="lastName"
                label=" Lastname"
                name="lastName"
                type="text"
                autoComplete="lastName"
                autoFocus
                value={state.lastName}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>

          <TextField
            required={true}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
          />
          <TextField
            required={true}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={passwordError != ""}
            helperText={passwordError != "" ? "Enter valid password" : ""}
            autoComplete="current-password"
            value={state.password}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
          />
          <Box mb={1} mt={2}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{
                textTransform: "capitalize",
                padding: "10px",
                color: "white",
              }}
            >
              {loading ? "Registering..." : "Register"}
            </Button>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Button
                fullWidth
                style={{ textTransform: "capitalize" }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/login");
                }}
              >
                {" Login"}
              </Button>
            </Grid>
          </Grid>
          <Grid>
            {graphQlError && (
              <Alert severity="error">
                {graphQlError.graphQLErrors.map(({ message }, i) => (
                  <span key={i}>{message}</span>
                ))}
              </Alert>
            )}
          </Grid>
        </form>
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Registered Successfully. Please login to continue!
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
}
