import { gql } from "@apollo/client";

export const GET_ITEM = gql`
  query getItem {
    getItem {
      id
      Name
      Price
      Category
      DiscountedPrice
      Ingredients {
        id
        Name
        Amount
        Origin
        Unit
      }
      Description
      Availability
      Image
      Fk_TaxCategory
      Fk_Currency
      Fk_Menu
    }
  }
`;

export const GET_ITEM_BY_ID = gql`
  query getItemById($id: Int!) {
    getItemById(id: $id) {
      Name
      Price
      Category
      Ingredients {
        Name
      }
      Description
      Availability
      Image
      DiscountedPrice
      Fk_TaxCategory
      Fk_Currency
      Fk_Menu
    }
  }
`;
const IngredientsInput = gql`
  input IngredientsInput {
    Name: String
    Amount: Float
    Origin: String
    Unit: String
    Fk_Item: Int
  }
`;

export const ADD_ITEM = gql`
  mutation addItem(
    $name: String!
    $price: Float!
    $category: String!
    $ingredients: [IngredientsInput]
    $description: String!
    $availability: Boolean
    $image: String!
    $fk_menu: Int!
    $discountedPrice: Float
    $fk_restaurant: Int!
  ) {
    addItem(
      Name: $name
      Price: $price
      Category: $category
      Ingredients: $ingredients
      Description: $description
      Availability: $availability
      DiscountedPrice: $discountedPrice
      Image: $image
      Fk_Menu: $fk_menu
      Fk_Restaurant: $fk_restaurant
    ) {
      Name
      Category
      Ingredients {
        Name
      }
      Description
      Availability
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation updateItem(
    $id: Int!
    $name: String!
    $price: Float!
    $category: String!
    $discountedPrice: Float
    $ingredients: [IngredientsInput]
    $description: String!
    $availability: Boolean
    $image: String!
    $fk_menu: Int!
    $fk_restaurant: Int!
  ) {
    updateItem(
      id: $id
      Name: $name
      Price: $price
      Category: $category
      Ingredients: $ingredients
      Description: $description
      DiscountedPrice: $discountedPrice

      Availability: $availability
      Image: $image
      Fk_Menu: $fk_menu
      Fk_Restaurant: $fk_restaurant
    ) {
      Name
      Category
      Ingredients {
        id
        Name
        Amount
        Origin
        Unit
      }
      Description
      Availability
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation deleteItem($id: Int!) {
    deleteItem(id: $id)
  }
`;
