import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import {
  GET_MENU,
  GET_RESTAURANT_BY_ID,
  EDIT_MENU,
} from "../../pages/Menu/graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Box, Paper, Button, Typography } from "@material-ui/core";
import { ADD_MENU } from "../../pages/Menu/graphql";
import { useMutation } from "@apollo/client";

import MenuItem from "../MenuItem";

import { useParams } from "react-router-dom";
import AddMenuDialog from "../AddMenuDialog";
import Axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({}));

function Menu(props) {
  const { window } = props;
  const classes = useStyles();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState();
  const [fk_restaurant, setFk_restaurant] = React.useState();

  const [selectedValue, setSelectedValue] = React.useState();
  const [getRestaurantById, { error, loading, data }] = useLazyQuery(
    GET_RESTAURANT_BY_ID,
    {
      variables: {
        id: +id,
      },
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    getRestaurantById();
  }, []);
  const [
    addMenu,
    { loading: resData, error: graphQlError, data: dataRes },
  ] = useMutation(ADD_MENU);

  const [
    updateMenu,
    { loading: editLoading, error: editErr, data: editMenuData },
  ] = useMutation(EDIT_MENU);

  if (error) {
    return "Some thing went wrong";
  }
  if (loading) {
    return "loading...";
  }

  const handleOpenAddMenuDialog = () => {
    setOpen(true);
  };

  const handleAddMenuDialogClose = () => {
    setOpen(false);
  };

  const createMenu = async (name) => {
    try {
      await addMenu({
        variables: {
          name: name,
          fk_restaurant: +id,
        },
      }).then((res) => {
        getRestaurantById();
        setOpen(false);
        setName("");
      });
    } catch (e) {
      console.error(e);
    }
  };
  const editMenu = async (currentEditMenu) => {
    try {
      await updateMenu({
        variables: {
          id: currentEditMenu.id,
          name: currentEditMenu.name,
          fk_restaurant: +id,
        },
      }).then((res) => {
        getRestaurantById();
        setOpen(false);
        setName("");
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <MenuItem
        editMenu={editMenu}
        data={data}
        handleOpenAddMenuDialog={handleOpenAddMenuDialog}
      />
      <AddMenuDialog
        name={name}
        setName={setName}
        fk_restaurant={fk_restaurant}
        setFk_restaurant={setFk_restaurant}
        open={open}
        onClose={handleAddMenuDialogClose}
        selectedValue={selectedValue}
        createMenu={createMenu}
      />
    </div>
  );
}

export default Menu;
