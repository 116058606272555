import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import RegisterForm from "../../components/RegisterForm";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage:
            "url(https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80)",
        backgroundPepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh"

    },
}));

export default function Register() {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Box
                display="flex"
                minHeight="100vh"
                justifyContent="center"
                flexDirection="column">
                <RegisterForm />
            </Box>
        </div>
    );
}
