import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import QrReader from "react-qr-scanner";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import InputAdornment from "@material-ui/core/InputAdornment";

import Dialog from "@material-ui/core/Dialog";

import { blue } from "@material-ui/core/colors";
import {
  Box,
  Button,
  DialogTitle,
  Grid,
  TextField,
  ThemeProvider,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  text: {
    color: "white",
    textTransform: "capitalize",
  },
});

function EditTableDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
    selectedValueEdit,
    handleEditQrCodeScan,
    updateTAble,
    currentTable,
    handleOpenEditDialogClose,
    handleEditTableForm,
    openQrCode,
    setOpenQrCode,
  } = props;

  const [delay, setDelay] = useState(5000);

  const handleOpenQrCode = () => {
    setOpenQrCode(true);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleClose = () => {
    onClose(selectedValueEdit);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Edit Table</DialogTitle>
      <Box display="flex" flexDirection="column" justifyContent="center" p={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <form
              onSubmit={(e) => updateTAble(e)}
              className={classes.root}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Number"
                variant="outlined"
                value={currentTable.Number}
                name="number"
                onChange={(e) => handleEditTableForm(e)}
                margin="normal"
              />
              <TextField
                margin="normal"
                id="outlined-basic"
                label="QR-Code"
                variant="outlined"
                name="qr_code"
                value={currentTable.QR_Code}
                onClick={handleOpenQrCode}
                onChange={(e) => handleEditTableForm(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <CameraAltIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {openQrCode == true && (
                <QrReader
                  style={{ height: 100, width: 270 }}
                  onError={handleError}
                  onScan={handleEditQrCodeScan}
                  delay={delay}
                />
              )}

              <Box my={1}>
                <Button
                  className={classes.text}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </Box>

              <Button
                style={{
                  color: "grey",
                }}
                fullWidth
                className={classes.text}
                variant="none"
                onClick={handleOpenEditDialogClose}
              >
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

EditTableDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default EditTableDialog;
