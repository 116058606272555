import React, { useEffect } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Avatar } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_TABLE } from "../../pages/Details/graphql";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 450,
    // border: "none",
    borderBottom: "none",
  },
  button: {
    margin: theme.spacing(1),
    color: "white",
  },
  tableCell: {
    fontWeight: "bold",
  },
}));

export default function DenseTable({
  handleDeleteDialogOpen,
  handleEditDialogOpen,
}) {
  const classes = useStyles();
  const { id } = useParams();

  const [getTable, { loading, error, data }] = useLazyQuery(GET_TABLE, {
    variables: {
      id: +id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getTable();
  }, []);

  return (
    <TableContainer className={classes.table}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              Name{" "}
            </TableCell>

            <TableCell className={classes.tableCell} align="center">
              Qr-Code{" "}
            </TableCell>

            <TableCell className={classes.tableCell} align="center">
              Action{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.getTable.map((table) => (
              <TableRow>
                <TableCell align="center" component="th" scope="row">
                  {table.Number}
                </TableCell>
                <TableCell align="center">{table.QR_Code}</TableCell>
                <TableCell align="center">
                  {
                    <IconButton
                      onClick={() => handleEditDialogOpen(table)}
                      aria-label="edit"
                      className={classes.margin}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  }
                  {
                    <IconButton
                      onClick={() => handleDeleteDialogOpen(table.id)}
                      aria-label="delete"
                      className={classes.margin}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
