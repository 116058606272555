import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Image } from "cloudinary-react";
import { CircularProgress, IconButton } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import IngredientsTable from "../IngredientsTable";
import IngredientsDialog from "../IngredientsDialog";
const useStyles = makeStyles({
  root: {
    // flexGrow: 1,
    // display: "flex",
    // flexDirection: "row",
  },
  contaier: {
    display: "flex",
    flexDirection: "column",
  },

  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  controlButtonStyle: {
    width: "5rem",
  },
});

export default function AddItemDialog(props) {
  const [showIngredientsDialog, setShowIngredientsDialog] = useState(false);
  const [ingredientsState, setIngredientsState] = useState([]);
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    createItem,
    Name,
    setName,
    Description,
    setDescription,
    Price,
    setPrice,
    Category,
    setCategory,
    DiscountedPrice,
    setDiscountedPrice,
    setMenuItemImageSelected,
    menuITemImageLoading,
    setMenuItemImageLoading,
    menuItemImageUrl,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleAddMenuItem = (e) => {
    e.preventDefault(e);
    setMenuItemImageLoading(true);
    createItem(ingredientsState, setIngredientsState([]));
  };

  const handleShowIngredientsDialog = (value) => {
    setShowIngredientsDialog(value);
  };

  const handleIngredientsSubmit = (ingredientData) => {
    setIngredientsState([...ingredientsState, ingredientData]);
    setShowIngredientsDialog(false);
  };

  const handleDeleteIngredient = (ingredientName) => {
    let ingData = ingredientsState.filter((ing) => ing.Name !== ingredientName);
    setIngredientsState(ingData);
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>
        {" "}
        <Typography variant="h4" color="initial">
          Add Menu Item
        </Typography>
      </DialogTitle>

      <Box mx={1} p={1}>
        <form
          onSubmit={(e) => handleAddMenuItem(e)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Name"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required={true}
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                label="Price"
                required={true}
                value={Price}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                // size="small"
                label="Description"
                required={true}
                type="text"
                value={Description}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={3}
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Discounted Price"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={DiscountedPrice}
                fullWidth
                onChange={(e) => setDiscountedPrice(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="flex-start">
                <TextField
                  type="file"
                  size="small"
                  className={classes.textfield}
                  margin="dense"
                  style={{ minWidth: "200px", maxWidth: "236px" }}
                  id="outlined-basic"
                  variant="outlined"
                  label="select image"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setMenuItemImageSelected(e.target.files[0])}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography>Ingredients</Typography>
                <IngredientsTable
                  handleShowIngredientsDialog={handleShowIngredientsDialog}
                  ingredients={ingredientsState}
                  handleDeleteIngredient={handleDeleteIngredient}
                />
              </Box>
            </Grid>
          </Grid>
          <Box p={2} minWidth="xs" display="flex" justifyContent="center">
            <Button
              onClick={handleClose}
              variant="text"
              color="primary"
              disableRipple
              style={{ padding: "0 4rem" }}
            >
              Cancel
            </Button>

            {menuITemImageLoading === true ? (
              <CircularProgress size="3rem" />
            ) : (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ padding: "5px 4rem", color: "white" }}
              >
                Save
              </Button>
            )}
          </Box>
        </form>
      </Box>
      <IngredientsDialog
        showIngredientsDialog={showIngredientsDialog}
        // setShowIngredientsDialog={setShowIngredientsDialog}
        handleShowIngredientsDialog={handleShowIngredientsDialog}
        ingredientsState={ingredientsState}
        setIngredientsState={setIngredientsState}
        ingredientsState={ingredientsState}
        handleIngredientsSubmit={handleIngredientsSubmit}
      />
    </Dialog>
  );
}

AddItemDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
