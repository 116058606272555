import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import PrimaryLayout from "./layouts/PrimaryLayout";
import UnAuthorizedLayout from "./layouts/UnAuthLayout";
import AuthorizedRoute from "./Utils/AuthorizedRoute";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={UnAuthorizedLayout} />
        <AuthorizedRoute path="/app" component={PrimaryLayout} />
        <Redirect to="/auth/login" />
      </Switch>
    </BrowserRouter>
  );
}
