import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import Restaurant from '../../pages/Restaurant';
import Menu from '../../pages/Menu';
import Details from '../../pages/Details';
import Order from '../../pages/Order';

export default function RestaurantSubLayout({ match }) {
  return (
    <div>
      <Switch>
        <Route path={match.path} exact component={Restaurant} />
        <Route exact path={`${match.path}/details/:id`} component={Details} />
        <Route exact path={`${match.path}/menu/:id`} component={Menu} />
        <Route exact path={`${match.path}/order/:id`} component={Order} />
      </Switch>
    </div>
  );
}
