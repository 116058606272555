import React from "react";
import MyLogin from "../../components/Login";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Bg from "../../assets/images/bg.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${Bg})`,
    backgroundPepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100vh",
  },
}));

export default function Login() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box
        display="flex"
        minHeight="100vh"
        justifyContent="center"
        flexDirection="column"
      >
        <MyLogin />
      </Box>
    </div>
  );
}
