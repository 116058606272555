import { gql } from "@apollo/client";

export const GET_RESTAURANT = gql`
  query getRestaurant {
    getRestaurant {
      id
      Name
      Phone
      City
      Mail
      PLZ
      Category
      Phone_for_Orders
      Is_Open
      Fk_User
    }
  }
`;

export const GET_RESTAURANT_BY_ID = gql`
  query getRestaurantById($id: Int!) {
    getRestaurantById(id: $id) {
      id
      Name
      Phone
      City
      Mail
      PLZ
      Category
      Phone_for_Orders
      Is_Open
      Fk_User
    }
  }
`;

export const GET_RESTAURANTS_BY_USER = gql`
  query getRestaurantsByUser {
    getRestaurantsByUser {
      id
      Name
      Phone
      City
      Mail
      PLZ
      Category
      Phone_for_Orders
      Is_Open
      Fk_User
    }
  }
`;

export const ADD_RESTAURANT = gql`
  mutation addRestaurant(
    $name: String!
    $phone: String!
    $city: String!
    $mail: String!
    $plz: String!
    $category: String!
    $phone_for_orders: String!
    $is_open: Boolean!
  ) {
    addRestaurant(
      Name: $name
      Phone: $phone
      City: $city
      Mail: $mail
      PLZ: $plz
      Category: $category
      Phone_for_Orders: $phone_for_orders
      Is_Open: $is_open
    )
  }
`;

export const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant(
    $id: Int!
    $name: String
    $phone: String
    $city: String
    $mail: String
    $plz: String
    $category: String
    $phone_for_orders: String
    $fk_user: Int!
  ) {
    updateRestaurant(
      id: $id
      Name: $name
      Phone: $phone
      City: $city
      Mail: $mail
      PLZ: $plz
      Category: $category
      Phone_for_Orders: $phone_for_orders
      Fk_User: $fk_user
    )
  }
`;

export const UPDATE_RESTAURANT_STATUS = gql`
  mutation updateRestaurantOpening($id: Int!, $is_open: Boolean) {
    updateRestaurantOpening(id: $id, Is_Open: $is_open)
  }
`;
