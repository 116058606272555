import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, CardMedia, Paper, Typography } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { Button } from '@material-ui/core';
import DetailsRoundedIcon from '@material-ui/icons/DetailsRounded';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',

    minWidth: 450,
    display: 'flex',
    margin: theme.spacing(1),
  },

  content: {
    padding: theme.spacing(3),
  },
  CardContent: {
    width: '100%',
  },
  Button: {
    color: 'white',
    maxHeight: '20px',
    padding: '13px 40px 13px 40px ',
    textTransform: 'capitalize',
  },
  Paper: {
    padding: '10px',
    width: ' 92px;',
    borderRadius: '12%',
  },
  RestaurantMenu: {
    cursor: 'pointer',
  },
}));
function RestaurantItem({
  restrauntName,
  restrauntId,
  resPLZ,
  restrauntImage,
}) {
  const classes = useStyles();
  const history = useHistory();
  const openRestaurantMenu = () => {
    history.push(`restaurant/menu/${restrauntId}`);
  };

  const openRestaurantDetail = () => {
    history.push(`restaurant/details/${restrauntId}`);
  };
  const handleOrder = () => {
    history.push(`restaurant/order/${restrauntId}`);
  };
  return (
    <Card className={classes.root}>
      <CardContent className={classes.CardContent}>
        <Box
          className={classes.BoxContainer}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        >
          <Box
            className={classes.BoxItemONe}
            display='flex'
            justifyContent='space-around'
            minWidth='320px'
          >
            <Box>
              <Typography variant='h5' color='initial'>
                {restrauntName}
              </Typography>

              <Typography>{resPLZ}</Typography>
            </Box>

            <Box>
              <Button
                className={classes.Button}
                size='large'
                variant='contained'
                color='primary'
                onClick={handleOrder}
              >
                Orders
              </Button>
            </Box>
          </Box>
          <Box
            className={classes.BoxItemTwo}
            display='flex'
            elevation={1}
            justifyContent='space-evenly'
            my={1}
          >
            <Paper className={classes.Paper} elevation={3}>
              <Box
                className={classes.RestaurantMenu}
                display='flex'
                justifyContent='center'
                flexDirection='column'
                alignContent='center'
                alignItems='center'
                onClick={openRestaurantMenu}
              >
                <Typography variant='p' color='initial'>
                  Menu
                </Typography>
                <ListRoundedIcon style={{ fontSize: 50 }} fontSize='large' />
              </Box>
            </Paper>
            <Paper className={classes.Paper} elevation={3}>
              <Box
                className={classes.RestaurantMenu}
                display='flex'
                justifyContent='center'
                flexDirection='column'
                alignContent='center'
                alignItems='center'
                onClick={openRestaurantDetail}
              >
                <Typography variant='p' color='initial'>
                  Details
                </Typography>
                <DetailsRoundedIcon style={{ fontSize: 50 }} />
              </Box>
            </Paper>
            <Paper className={classes.Paper} elevation={3}>
              <Box
                className={classes.RestaurantMenu}
                display='flex'
                justifyContent='center'
                flexDirection='column'
                alignContent='center'
                alignItems='center'
                onClick={openRestaurantDetail}
              >
                <Typography variant='p' color='initial'>
                  Payment
                </Typography>
                <PaymentRoundedIcon style={{ fontSize: 50 }} />
              </Box>
            </Paper>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default RestaurantItem;
