import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import countryList from "../../Utils/countries";
import { weights } from "../../Utils/weightUnits";
const useStyles = makeStyles({
  root: {
    // flexGrow: 1,
    // display: "flex",
    // flexDirection: "row",
  },
  contaier: {
    display: "flex",
    flexDirection: "column",
  },

  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  controlButtonStyle: {
    width: "5rem",
  },
});

export default function IngredientsDialog({
  showIngredientsDialog,
  handleShowIngredientsDialog,
  setIngredientsState,
  ingredientsState,
  handleIngredientsSubmit,
}) {
  const [Name, setName] = useState("");
  const [Amount, setAmount] = useState("");
  const [Origin, setOrigin] = useState("");
  const [Unit, setUnit] = useState("");
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    let ingredientData = {
      Name: Name,
      Amount: +Amount,
      Origin: Origin,
      Unit: Unit,
    };
    handleIngredientsSubmit(ingredientData);
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={() => handleShowIngredientsDialog(false)}
      aria-labelledby="simple-dialog-title"
      open={showIngredientsDialog}
    >
      <DialogTitle>Add Ingredients</DialogTitle>
      <Box mx={1} p={1}>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Name"
                size="small"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                // required={true}
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Amount"
                size="small"
                fullWidth
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                // required={true}
                value={Amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Origin"
                size="small"
                margin="dense"
                select
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                // required={true}
                value={Origin}
                onChange={(e) => setOrigin(e.target.value)}
              >
                {countryList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Unit"
                size="small"
                margin="dense"
                fullWidth
                select
                InputLabelProps={{
                  shrink: true,
                }}
                // required={true}
                value={Unit}
                onChange={(e) => setUnit(e.target.value)}
              >
                {weights.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Box p={2} minWidth="xs" display="flex" justifyContent="center">
            <Button
              onClick={() => handleShowIngredientsDialog(false)}
              variant="text"
              color="primary"
              disableRipple
              style={{ padding: "0 4rem" }}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ padding: "5px 4rem", color: "white" }}
            >
              Add
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

IngredientsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
