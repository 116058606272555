import { Box, Grid, Typography, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import OrderTable from "../OrderTable";
import { useLazyQuery, useQuery, useMutation, useSubscription } from "@apollo/client";
import {
  GET_ORDERS_BY_RESTAURANT_ID,
  ORDERS_SUBSCRIPTION,
  ORDERS_ITEM_SUBSCRIPTION,
  UPDATE_ORDER,
} from "../../pages/Order/graphql";

const useStyles = makeStyles((theme) => ({
  orderCard: {
    marginBottom: "12px",
  },
  orderCardTop: {
    color: "gray",
  },
  clientId: {
    margin: "10px",
  },
}));

function Order() {
  const classes = useStyles();
  const { id } = useParams();
  const orderSubsription = useSubscription(
    ORDERS_SUBSCRIPTION,
    {
      fetchPolicy: "network-only",
      variables: {
        restaurantId: +id,
      },
    }
  )
  const orderItemSubsription = useSubscription(
    ORDERS_ITEM_SUBSCRIPTION,
    {
      fetchPolicy: "network-only",
      variables: {
        restaurantId: +id,
      },
    }
  )
  const [getOrders, { loading, data, subscribeToMore }] = useLazyQuery(
    GET_ORDERS_BY_RESTAURANT_ID,
    {
      fetchPolicy: "network-only",

    }
  );
  const [updateOrder] = useMutation(UPDATE_ORDER, {
    refetchQueries: [
      { query: GET_ORDERS_BY_RESTAURANT_ID, variables: { id: +id } },
    ],
  });

  useEffect(() => {
    getOrders({
      variables: {
        id: +id,
      },
    });
  }, []);

  useEffect(() => {
    if(subscribeToMore) {
      const updateOrders = subscribeToMore({
        document: ORDERS_SUBSCRIPTION,
        variables: {
          restaurantId: +id,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.orderAdded;
          return Object.assign({}, prev, {
            getOrdersByRestaurantId: [newFeedItem, ...prev.getOrdersByRestaurantId]
          });
        }
      })
      const updateOrderItems = subscribeToMore({
        document: ORDERS_ITEM_SUBSCRIPTION,
        variables: {
          restaurantId: +id,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const item = subscriptionData.data.orderItemAdded;
          const newOrderItemId = subscriptionData.data.orderItemAdded.Fk_Order;
          const feedOrder = prev.getOrdersByRestaurantId.filter((order) => order.id === newOrderItemId)[0];
          if (!feedOrder) return prev;
          const feedItem = Object.assign({}, feedOrder, {
            items: [item, ...feedOrder.items]
          });
          const newPrev = {getOrdersByRestaurantId: [...prev.getOrdersByRestaurantId]}
          newPrev.getOrdersByRestaurantId.splice(newOrderItemId-1, 1, feedItem);
          return newPrev;
        }
      })
      return () => {
        updateOrders();
        updateOrderItems();
      }
    }
  }, [subscribeToMore]);

  const handlePaidClick = (id) => {
    updateOrder({
      variables: {
        id,
        state: "PAID",
      },
    });
  };

  const handleDeliveredClick = (id) => {
    updateOrder({
      variables: {
        id,
        state: "DELIVERED",
      },
    });
  };

  if (loading) return <p>Loading...</p>;
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item sm={9}>
        <Box display="flex" flexDirection="column" mt={12}>
          <Typography variant="h6" color="initial">
            Orders
          </Typography>
          {data &&
            data.getOrdersByRestaurantId.map((order) => (
              <Paper key={`order_${order.id}`} className={classes.orderCard}>
                <Grid className={classes.orderCardTop} item sm={12}>
                  <Typography className={classes.clientId}>
                    Client ID: {order.ClientId}
                  </Typography>
                </Grid>
                <OrderTable
                  style={{
                    width: "100%",
                  }}
                  dataItem={order.items}
                />
                <Grid
                  justifyContent="flex-end"
                  className={classes.orderCardTop}
                  container
                  md={12}
                >
                  {["READY"].includes(order.State) && <Button
                    variant="contained"
                    style={{
                      color: "white",
                      paddingLeft: 30,
                      paddingRight: 30,
                      textTransform: "capitalize",
                      width: "70px",
                      margin: "10px",
                    }}
                    color="primary"
                    onClick={() => handlePaidClick(order.id)}
                  >
                    Paid
                  </Button>}
                  {["PAID"].includes(order.State) && <Button
                      variant="contained"
                      style={{
                        color: "white",
                        paddingLeft: 30,
                        paddingRight: 30,
                        textTransform: "capitalize",
                        width: "70px",
                        margin: "10px",
                      }}
                      color="primary"
                      onClick={() => handleDeliveredClick(order.id)}
                    >
                      Delivered 
                  </Button>}
                </Grid>
              </Paper>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default Order;
