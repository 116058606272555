import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import Login from "../../pages/Login";
import Dashboard from "../../pages/Dashboard";
import Restaurant from "../../pages/Restaurant";
import Report from "../../pages/Report";
import Account from "../../pages/Account";
import Register from "../../pages/Register";
import Sider from "../../components/Sider";
import RestaurantSubLayout from "../RestaurantSubLayout";

export default function PrimaryLayout() {
    return (
        <div>
            <Sider />
            <main style={{ marginLeft: "20%" }}>
                <Switch>
                    <Route exact path="/app/dashboard" component={Dashboard} />
                    <Route path="/app/restaurant" component={RestaurantSubLayout} />
                    <Route exact path="/app/report" component={Report} />
                    <Route exact path="/app/account" component={Account} />
                    <Redirect to="auth/login" />
                </Switch>
            </main>
        </div>
    );
}
