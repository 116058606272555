import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Avatar, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
    color: "white",
  },
}));

export default function DenseTable({
  data,
  handleOpenAddMenuItemDialog,
  handleOpenEditMenuItemDialog,
  handleOpenDeleteMenuItemDialog,
}) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell># </TableCell>

            <TableCell>Name </TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Category</TableCell>
            <TableCell align="center">Ingredients</TableCell>

            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Availability</TableCell>

            <TableCell align="center"></TableCell>

            <TableCell align="center">
              {
                <IconButton
                  onClick={handleOpenAddMenuItemDialog}
                  aria-label="add"
                  className={classes.margin}
                >
                  <AddBoxIcon style={{ color: "#37a9ec" }} fontSize="large" />
                </IconButton>
              }
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length === 0 && (
            <Box p={4} display="flex" align="center">
              <Typography align="center" color="error">
                no items
              </Typography>
            </Box>
          )}
          {data &&
            data.length > 0 &&
            data.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{row.Name}</TableCell>
                <TableCell align="center">{row.Price}</TableCell>

                <TableCell align="center">{row.Category}</TableCell>
                <TableCell align="center">
                  {row.Ingredients &&
                    row.Ingredients.length &&
                    row.Ingredients.map((r) => r.Name).join(",")}
                </TableCell>
                <TableCell align="center">{row.Description}</TableCell>

                <TableCell align="center">
                  {row.Availability ? (
                    <Typography variant="subtitle2" color="primary">
                      Available
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" color="error  ">
                      Not Available
                    </Typography>
                  )}
                </TableCell>

                <TableCell align="center">
                  <Avatar src={row.Image} />
                </TableCell>
                <TableCell align="center">
                  {
                    <IconButton
                      onClick={() => handleOpenEditMenuItemDialog(row)}
                      aria-label="edit"
                      className={classes.margin}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  }
                  {
                    <IconButton
                      onClick={() => handleOpenDeleteMenuItemDialog(row.id)}
                      aria-label="delete"
                      className={classes.margin}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
