import React, { useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import logoBig from "../../assets/images/logoBig.png";
import { Box, Button, ListItemIcon } from "@material-ui/core";
import DashboardIcon from '@material-ui/icons/Dashboard';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ReportIcon from '@material-ui/icons/Report';
import { useHistory } from "react-router-dom";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { logout } from "../../Utils/logout";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbarStyles: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

function Sider(props) {
    const [selectedNavItem, setSelectedNavItem] = useState('Dashboard')
    const { window } = props;
    const classes = useStyles();
    const history = useHistory();

    const theme = useTheme();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // const handleNavItem = (navItem) => {
    //     setSelectedNavItem(navItem)
    //     console.log(props)
    //     history.push(navItem);
    // }

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <Box display="flex" justifyContent="center">
                    <img src={logoBig} height="60px" width="auto" />
                </Box>
            </div>
            <Divider />
            <List component="nav">
                <ListItem
                    selected={props.page == "Dashboard"}
                    button
                    component={NavLink} to="/app/dashboard"
                    activeStyle={{
                        fontWeight: "600",
                        color: "black"
                    }}
                >
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                </ListItem>

                <ListItem
                    button
                    component={NavLink} to="/app/restaurant"
                    activeStyle={{
                        fontWeight: "600",
                        color: "black"
                    }}
                >
                    <ListItemIcon>
                        <RestaurantIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Restaurant"} />
                </ListItem>
                <ListItem
                    button
                    component={NavLink} to="/app/report"
                    activeStyle={{
                        fontWeight: "600",
                        color: "black"
                    }}
                >
                    <ListItemIcon>
                        <ReportIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Report"} />
                </ListItem>
                <ListItem
                    button
                    component={NavLink} to="/app/account"
                    activeStyle={{
                        fontWeight: "600",
                        color: "black"
                    }}
                >
                    <ListItemIcon>
                        <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Account"} />
                </ListItem>
            </List>
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbarStyles} >
                    <IconButton
                        color="red"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {props.page}
                    </Typography>
                    <Button variant="filled" color="secondary"
                        onClick={() => logout()}
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}

export default Sider;
