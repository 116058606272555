import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";

import { blue } from "@material-ui/core/colors";
import {
  Box,
  Button,
  DialogTitle,
  Grid,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import QRCode from "react-qr-code";
import QrReader from "react-qr-scanner";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  text: {
    color: "white",
    textTransform: "capitalize",
  },
});

function AddRestaurantDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
    selectedValue,
    qr_code,
    setQr_code,
    handleAddScanQrCode,
    createTable,
    number,
    setNumber,
    openAddQrCode,
    setOpenAddQrCode,
  } = props;
  const [delay, setDelay] = useState(5000);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleAddTable = (e) => {
    e.preventDefault();
    {
      qr_code != "" && number != "" && createTable(qr_code, number);
    }
    console.log("sdasd");
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleOpenQrCode = () => {
    setOpenAddQrCode(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Add Table</DialogTitle>
      <Box display="flex" flexDirection="column" justifyContent="center" p={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <form
              onSubmit={(e) => handleAddTable(e)}
              className={classes.root}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Number"
                variant="outlined"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                margin="normal"
              />
              <TextField
                id="outlined-basic"
                label="QR-Code"
                variant="outlined"
                value={qr_code}
                onClick={handleOpenQrCode}
                onChange={(e) => setQr_code(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <CameraAltIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box mt={2} alignSelf="center">
                {openAddQrCode != false && (
                  <QrReader
                    style={{ height: 100, width: 270 }}
                    onError={handleError}
                    onScan={handleAddScanQrCode}
                    delay={delay}
                  />
                )}
              </Box>

              <Box my={1}>
                <Button
                  className={classes.text}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </Box>

              <Button
                onClick={handleClose}
                style={{
                  color: "grey",
                }}
                fullWidth
                className={classes.text}
                variant="none"
              >
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

AddRestaurantDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default AddRestaurantDialog;
