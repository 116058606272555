import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

import { blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
const useStyles = makeStyles({
  contaier: {
    display: "flex",
    flexDirection: "column",
  },

  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  controlButtonStyle: {
    width: "5rem",
  },
});

export default function DeleteTableDialog(props) {
  const classes = useStyles();
  const {
    selectedValueDel,
    // setOpenDelDialog,
    deleteTableId,
    deleteTAble,
    open,
    onClose,
  } = props;

  const handleClose = () => {
    onClose(selectedValueDel);
  };

  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure, you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button
          onClick={() => deleteTAble(deleteTableId)}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteTableDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
