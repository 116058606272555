import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Image } from "cloudinary-react";
import { CircularProgress } from "@material-ui/core";

import { blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Button,
  Dialog,
  Paper,
  Grid,
  Typography,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function AddMenuDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    name,
    fk_restaurant,
    setName,
    createMenu,
    setMenuImageSelected,
    uploadMenuImage,
    menuImageLoading,
    menuImageUrl,
    menuImageSelected,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleAddMenu = (e) => {
    e.preventDefault();
    createMenu(name, fk_restaurant);
    console.log("name and fk,", name, fk_restaurant);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>ADD MENU</DialogTitle>
      <Box display="flex" flexDirection="column" justifyContent="center" p={2}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <form
              onSubmit={(e) => handleAddMenu(e)}
              className={classes.root}
              noValidate
              autoComplete="off"
            >
              <TextField
                fullWidth
                margin="dense"
                id="outlined-basic"
                label="name"
                variant="outlined"
                required={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Box my={1}>
                <Button
                  className={classes.text}
                  fullWidth
                  variant="contained"
                  style={{ color: "white" }}
                  color="primary"
                  type="submit"
                >
                  Add
                </Button>
              </Box>
              <Box>
                <Button
                  fullWidth
                  onClick={handleClose}
                  variant="oulined"
                  color="primary"
                  style={{ padding: "5px 4rem" }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

AddMenuDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
