import React from 'react';
import { Route, Redirect } from 'react-router-dom';

class AuthorizedRoute extends React.Component {


    render() {
        const isAuthenticated =
            localStorage.getItem("authToken") !== null ? true : false;
        const { component: Component, pending, logged, ...rest } = this.props
        return (
            <Route {...rest} render={props => {
                return isAuthenticated
                    ? <Component {...this.props} />
                    : <Redirect to="/auth/login" />
            }} />
        )
    }
}



export default AuthorizedRoute;