import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  box: {
    marginTop: "12px",
    width: "100%",
  },
}));

export default function OrderTable({ dataItem }) {
  const classes = useStyles();

  let filteredData = dataItem;

  return (
    <Box mt={6} className={classes.box}>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell># </TableCell>

              <TableCell>Name </TableCell>
              <TableCell align="center">Date </TableCell>
              <TableCell align="center">Price(CHF)</TableCell>
              <TableCell align="center">Tax.(CHF)</TableCell>

              <TableCell align="center">Category</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData.length > 0 &&
              filteredData.map((row, index) => (
                <TableRow key={`order_item_${index}`}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{row.item.Name}</TableCell>
                  <TableCell align="center">{row.Created}</TableCell>

                  <TableCell align="center">{row.item.Price}</TableCell>
                  <TableCell align="center">0</TableCell>
                  <TableCell align="center">{row.item.Category}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
