import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CardHeader,
  Divider,
  Grid,
  GridListTileBar,
  IconButton,
  Typography,
} from "@material-ui/core";
import Table from "../RestaurantDetailTable";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "../Switch";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    // border: "1px solid red",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardStyle: {
    maxWidth: 345,
  },
  detailBox: {
    borderRadius: "5%",
    marginTop: "1rem  ",
  },
  detailList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detailListItem: {
    display: "flex",
    flexDirection: "column",
  },
  tableContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  detailHourButton: {
    marginTop: 0,
    padding: "0px 0px 1rem 0px",
  },
  margin: {
    padding: 0,
  },
  btn: {
    float: "right",
  },
  detailWidth: {
    width: "300px",
  },
}));

export default function RestaurantDetails({
  handleOpenEditRestaurantInfoDialog,
  deleteTAble,
  resName,
  setOpen,
  handleDeleteDialogOpen,
  handleEditDialogOpen,
  state,
  handleChange,
  handleOpenTableDialog,
  qrData,
}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid container maxWidth="xs" spacing={3}>
        <Grid item xs={12}>
          {" "}
          <Typography variant="h4" color="initial">
            {resName &&
              resName.getRestaurantById &&
              resName.getRestaurantById.Name}
          </Typography>
          <Typography variant="subtitle1" color="initial">
            {resName &&
              resName.getRestaurantById &&
              resName.getRestaurantById.PLZ}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box ml={1}>
            <Typography variant="h4" align="left">
              Details
            </Typography>
          </Box>
          <Box mt={2} display="flex" flexDirection="column">
            <Box style={{ textAlign: "right" }}>
              <IconButton
                onClick={() => handleOpenEditRestaurantInfoDialog(resName)}
                aria-label="edit"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box ml={1}>
              <Typography variant="subtitle1" color="initial">
                {resName &&
                  resName.getRestaurantById &&
                  resName.getRestaurantById.Phone}
              </Typography>
              <Typography
                style={{ marginTop: "4px" }}
                variant="subtitle1"
                color="initial"
              >
                {resName &&
                  resName.getRestaurantById &&
                  resName.getRestaurantById.Phone_for_Orders}
              </Typography>
              <Typography variant="subtitle1" color="initial">
                {resName &&
                  resName.getRestaurantById &&
                  resName.getRestaurantById.Mail}
              </Typography>
              <Typography variant="subtitle1" color="initial">
                {resName &&
                  resName.getRestaurantById &&
                  resName.getRestaurantById.City}
              </Typography>
              <Typography variant="subtitle1" color="initial">
                {resName &&
                  resName.getRestaurantById &&
                  resName.getRestaurantById.PLZ}
              </Typography>
              <Box m={2} display="flex" justifyContent="flex-end">
                <Switch state={state} handleChange={handleChange} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">Tables</Typography>
            <IconButton
              onClick={handleOpenTableDialog}
              aria-label="add"
              className={classes.margin}
            >
              <AddBoxIcon style={{ color: "#37a9ec" }} fontSize="medium" />
            </IconButton>
          </Box>
          <Box mt={2}>
            <Table
              handleDeleteDialogOpen={handleDeleteDialogOpen}
              deleteTAble={deleteTAble}
              handleEditDialogOpen={handleEditDialogOpen}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
