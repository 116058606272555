import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import AddBoxIcon from "@material-ui/icons/AddBox";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    color: "white",
  },
}));

export default function IngredientsTable({
  ingredients,
  handleShowIngredientsDialog,
  handleDeleteIngredient,
}) {
  const classes = useStyles();

  console.log("====================================");
  console.log(ingredients);
  console.log("====================================");
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Name </TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Origin</TableCell>
            {/* <TableCell align="center">Unit</TableCell> */}
            <TableCell align="center">
              {
                <IconButton
                  onClick={() => handleShowIngredientsDialog(true)}
                  aria-label="add"
                  //   className={classes.margin}
                >
                  <AddBoxIcon style={{ color: "#37a9ec" }} fontSize="large" />
                </IconButton>
              }
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredients &&
            ingredients.length > 0 &&
            ingredients.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.Name}</TableCell>
                <TableCell align="center">{row.Amount}</TableCell>

                <TableCell align="center">{row.Origin}</TableCell>
                {/* <TableCell align="center">{row.Unit}</TableCell> */}

                <TableCell align="center">
                  {
                    <IconButton
                      onClick={() => handleDeleteIngredient(row.Name)}
                      aria-label="delete"
                      //   className={classes.margin}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
