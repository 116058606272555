import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

import { blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
const useStyles = makeStyles({
  contaier: {
    display: "flex",
    flexDirection: "column",
  },

  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  controlButtonStyle: {
    width: "5rem",
  },
});

export default function EditItemDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, deleteItem, deleteMenuItemId } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog maxWidth="sm" aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle>Delete</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure, you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button
          onClick={() => deleteItem(deleteMenuItemId)}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditItemDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
