import { gql } from "@apollo/client";

export const GET_ORDERS_BY_RESTAURANT_ID = gql`
  query getOrdersItemsById($id: Int!) {
    getOrdersByRestaurantId(id: $id) {
      id
      OrderTime
      Printed
      State
      Fk_Restaurant
      ClientId
      items {
        Fk_Item
        Created
        Updated
        item {
          Name
          Price
          Category
        }
      }
    }
  }
`;

export const ORDERS_SUBSCRIPTION = gql`
  subscription Subscription($restaurantId: Int) {
    orderAdded(restaurantId: $restaurantId) {
      id
      OrderTime
      Printed
      TotalPrice
      State
      Fk_Restaurant
      ClientId
      items {
        Fk_Order
        Fk_Item
        Created
        Updated
        item {
          Name
          Price
          Category
          Description
          Availability
          DiscountedPrice
          Fk_TaxCategory
        }
      }
    }
  }
`
export const ORDERS_ITEM_SUBSCRIPTION = gql`
  subscription Subscription($restaurantId: Int) {
    orderItemAdded(restaurantId: $restaurantId) {
      Fk_Order
      Fk_Item
      Created
      Updated
      item {
        Name
        Price
        Category
        Description
        Availability
        DiscountedPrice
        Fk_TaxCategory
      }
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation updateOrder($id: Int!, $state: String!) {
    updateOrder(id: $id, state: $state)
  }
`;
