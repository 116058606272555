import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import { Image } from "cloudinary-react";
import { blue } from "@material-ui/core/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Box,
  Button,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  TextField,
  ThemeProvider,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  text: {
    color: "white",
  },
  textfield: {
    justifyContent: "center",
  },

  input: {
    display: "none",
  },
  imageUpload: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

function AddRestaurantDialog(props) {
  const classes = useStyles();

  const {
    onClose,
    selectedValue,
    open,
    createRestraunt,
    name,
    setName,
    phone,
    setPhone,
    city,
    setCity,
    mail,
    setMail,
    plz,
    setPlz,
    phone_for_orders,
    setPhone_for_orders,
    category,
    setCategory,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleAddRestaurant = (e) => {
    e.preventDefault();
    createRestraunt(name, phone, city, mail, plz, category, phone_for_orders);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Add Restaurant</DialogTitle>
      <Box mx={1} p={1}>
        <form
          onSubmit={(e) => createRestraunt(e)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={0}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textfield}
                  margin="dense"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textfield}
                  margin="dense"
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textfield}
                  margin="dense"
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textfield}
                  margin="dense"
                  id="outlined-basic"
                  label="Mail"
                  variant="outlined"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textfield}
                  margin="dense"
                  id="outlined-basic"
                  label="PLZ"
                  variant="outlined"
                  value={plz}
                  onChange={(e) => setPlz(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textfield}
                  margin="dense"
                  id="outlined-basic"
                  label="Category"
                  variant="outlined"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {/* <option>slkk</option> */}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textfield}
                  margin="dense"
                  id="outlined-basic"
                  label="Phone for orders (SMS)"
                  variant="outlined"
                  value={phone_for_orders}
                  onChange={(e) => setPhone_for_orders(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box m={2}>
                <Button
                  onClick={handleClose}
                  variant="oulined"
                  color="primary"
                  disableRipple
                  style={{ padding: "5px 4rem" }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box m={2}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ padding: "5px 4rem", color: "white" }}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}

AddRestaurantDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default AddRestaurantDialog;
